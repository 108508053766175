// const DEV = process.env.REACT_APP_DEV;
// const SERVER = DEV ? 'http://localhost:3100/api' : '/api';
// const FILE_BASE = 'https://api.datefirme.ro/';

const settings = {
    VERSION: '0.11.4',

    URL_BASE: process.env.REACT_APP_API_URL,

    INTERNAL_BACKEND_CONFIG: {
        headers: {
            Pragma: 'no-cache',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            API_KEY: process.env.REACT_APP_API_KEY,
            Authorization: process.env.REACT_APP_AUTH_KEY,
        }
    },

    LOCAL_STORAGE: {
        USER_KEY: 'DATE_FIRME_USER_KEY'
    },

    GOOGLE_API_KEY: {
        TRANSLATION: 'AIzaSyDfR87zckDLDoZ9dasK2xPfeBtsbNb7AyE'
    },

    EXTERNAL_REDIRECT: 'https://monitorizarefirme.ro/',

    FILES: {
        ACCOUNT_INVOICE: process.env.REACT_APP_API_URL + '/utils/download/downloadFactura',
        CONTRACT: process.env.REACT_APP_API_URL + '/api/contract/downloadContract',
        MOF: process.env.urlDateFirme + '/utils/download/DownloadArticolByTip/mof',     //mof sunt generate doar pe server
        GRM: process.env.REACT_APP_API_URL + '/utils/download/DownloadArticolByTip/grm',
        CIP: process.env.REACT_APP_API_URL + '/utils/download/downloadCIP',
        BPI_BULLETIN: process.env.REACT_APP_API_URL + '/utils/download/DownloadArticolByTip/bpi',
        BPI_ARTICLE: process.env.REACT_APP_API_URL + '/utils/download/downloadBpi',
        //BPI_ARTICLE_NEW: urlDateFirme + '/utils/download/downloadBpiNew',
    },

    UTILS: {
        LIMIT: 15,
        SEARCH_MAX_NO_OF_PAGES: 10,
    },

    URL_PATHS_API: {
        // USER
        LOGIN: '/login',
        LOGOUT: '/logout',

        EXTERNAL_LOGIN: '/account/loginfromdatefirme',

        CREATE_USER_ACCOUNT: '/cont',
        READ_USER_ACCOUNT: '/cont',
        UPDATE_USER_ACCOUNT: '/cont/portal',

        READ_USER_ACCOUNT_STATUS: '/cont/status',
        READ_USER_ACCOUNT_PACKAGE: '/cont/pachet',
        READ_USER_ACCOUNT_INVOICING_ADDRESSES: '/facturi/adrese',
        CREATE_USER_ACCOUNT_INVOICING_ADDRESS: '/facturi/adrese',
        UPDATE_USER_ACCOUNT_INVOICING_ADDRESS: '/facturi/adrese',
        SET_ACTIVE_USER_ACCOUNT_INVOICING_ADDRESS: '/facturi/adrese',
        DELETE_USER_ACCOUNT_INVOICING_ADDRESS: '/facturi/adrese',
        READ_USER_ACCOUNT_INVOICES: '/facturi',
        READ_USER_ACCOUNT_REPORTS_MARKETING: '/rapoarteMarketing',
        READ_USER_ACCOUNT_REPORTS_COMPANIES: '/rapoarteCompanii', 
        READ_USER_ACCOUNT_REPORTS_COMPANIES_OLD: '/rapoarteCompaniiOld', 
        CREATE_USER_CONTRACT: '/contract/user',
        GET_USER_TYPE: '/cont/userType',
        HAS_DRC_ACCESS: '/cont/drcAccess',
        COMANDA_DRC_DATE_NOI: '/cont/drcDateNoi',
        GET_USER_OPTIONS: '/cont/userOptions',
        EMAIL_UNSUBSCRIBE: '/dezabonareEmail',
        CHANGE_USER: '/changeUser',
        LOG_NEW_USER: '/logNewUser',
        UPDATE_REFERRER: '/updateReferrer',
        SESSION_LOGIN: '/sessionLogin',

        CHANGE_PASSWORD: '/cont/parola',
        CONFIRM: '/confirm',
        RESEND: '/retrimite',
        RECOVER: '/recupereaza',

        // DATA

        // STATISTICS
        READ_STATISTICS: '/sumarSaptamanal',

        // COMPANIES
        // READ_COMPANIES_WITH_ID: 'companiesInterrogations',
        READ_COMPANY_WITH_ID: '/companii',
        READ_COMPANY_WITH_CUI_FREE: '/companieFreeDetails',
        READ_COMPANY_HARTA_ACTIONARI_FREE: '/companiiHartaActionariFree',
        READ_COMPANY_EVOLUTIA_SOCIETATII_FREE: '/companiiEvolutiaSocietatiiFree',
        READ_COMPANY_GRAFICE_PROCESE_JUSTITIE_FREE: '/companiiGraficeProceseJustitieFree',
        READ_COMPANY_GRAFICE_INDICATORI_FREE: '/companiiGraficeIndicatoriFree',
        READ_LIMITA_CREDITE_FREE: '/companiiLimitaCreditFree',
        READ_RISK_RATING_FREE: '/companiiRiskRatingFree',
        SEARCH_COMAPNIES: '/companii/cautare',
        SEARCH_COMAPNIES_BY_FISCAL_CODE: '/companii/cautare/cui/bun',

        READ_SUGGESTED_COMPANIES: '/companii/cautare',
        READ_SUGGESTED_ASSOCIATES: '/persoane/companie',
        READ_SUGGESTED_COMPANIES_TOP_FISCAL_VALUE_COUNTY: '/companii/cautare',
        READ_SUGGESTED_COMPANIES_TOP_FISCAL_VALUE_CAEN: '/companii/cautare',
        READ_MONITORED_COMPANIES_FOR_USER: '/companii/monitorizare',
        READ_PORTFOLIO_COMPANIES_FOR_USER: '/companii/portofoliu',
        READ_COMPANIES_MARKETING_DATA: '/companii/marketing',
        READ_LAST_VIEWED_COMPANIES_BY_USER: '/companii/ultimele',
        GET_DOCUMENT_RADIERE: "/companii/getRaportRadiere/",


        // ASSOCIATES
        READ_ASSOCIATE_WITH_ID: '/persoane',
        SEARCH_ASSOCIATES: '/persoane/cautare',
        PORTFOLIO_ASSOCIATES: '/persoane/portofoliu',
        GET_FIRMAS_BY_FIRMA_ASSOCIATE: '/firme/actionari',

        //FINANCIAR
        GET_BILANT_EXTINS: '/bilantExtins',

        // DOSSIERS
        READ_MONITORED_DOSSIERS_FOR_USER: '/dosar/monitorizare',
        GET_DOSSIERS: '/dosar/getDosareInstanta',
        GET_MONITORED_DOSSIERS: '/dosar/getDosareMonitorizateByUser',
        SEARCH_MONITORED_DOSSIER: '/dosar/searchDosareMonitorizateByUser',

        // NOTIFICATIONS
        READ_MESSAGING_NOTIFICATIONS: '/notificari/mesaje',
        READ_MONITORING_NOTIFICATIONS: '/notificari/monitorizare',

        // ACQUISITIONS
        COMMIT_CART: '/achizitie/adauga',
        READ_ACQUISITION: '/achizitie',
        READ_USER_ACQUISTIONS_COUNT: '/achizitiiCount',
        PLATA_CUSTOM: '/achizitie/custom',

        //MONITORIZARE
        MONITOR: '/monitorizeaza',
        UNMONITOR: '/monitorizeaza',
        MONITOR_DOSAR: '/monitorizeazaDosar',
        UNMONITOR_DOSAR: '/opresteMonitorizareDosar',
        MONITOR_PERSON: '/monitorizeazaPersoana',
        UNMONITOR_PERSON: '/opresteMonitorizarePersoana',
        GET_MONITORED_PERSONS: '/getPersoaneMonitorizate',
        GET_MONITORED_PERSONS_WITH_BPI: '/getPersoaneMonitorizateCuDosar',
        SHEARCH_MONITORED_PERSON: '/cautaPersoanaMonitorizata',
        MONITOR_PEOPLE_FROM_FILE: '/monitorPeopleFromFile',

        // CONTACT
        SUBMIT_CONTACT_FORM: '/contact',

        // UTILS
        READ_COUNTIES: '/utils/judete',
        READ_INDUSTRIES: '/utils/industrii',
        READ_ALL_INDUSTRIES: '/utils/readAllIndustries',
        READ_ORDERS: '/utils/sortare',
        READ_FISCAL_CODE_DATA: '/utils/dateContract',
        READ_SEARCH_SUGGESTIONS: '/utils/searchSuggestions',

        // MESSSAGES
        READ_MESSAGES: '/mesaje',
        MARK_MESSAGE: '/mesaje',
        DELETE_MESSAGE: '/mesaje',

        //PACKS
        READ_PACKS: '/articol',

        //Raports
        LOAD_REPORT_HISTORY: '/companii/load/reportHistory',
        INTERROGATE_REPORT: '/interogheaza/raport',
        ISSUE_MULTIPLE_REPORTS: "/companii/issue/multiple/reports/",
        DOWNLOAD_MULTIPLE_RAPORT: "/utils/download/raportMultipluComplet/",
        UPDATE_BERC_REPORT: "/companii/updateBerc",

        //Dosare
        DOWNLOAD_DOSARE_INSTANTA: "/utils/download/dosareInstanta/",

        //Info Gratuite
        READ_INFO_GRATUITE_FIRME_JUDET: '/api/firmeByJudet', 
        READ_INFO_GRATUITE_COUNT_FIRME_BY_JUDET: '/api/countFirmeByJudet',
        READ_INFO_GRATUITE_FIRME_JUDETE: '/api/listaFirmeJudete',
        READ_INFO_GRATUITE_FIRME_LOCALIATI: '/api/listaFirmeLocalitati',

        //Admin
        GET_USERS_ADMIN: '/api/account',
        SEARCH_USER_ADMIN: '/api/account/search',
        UPDATE_USER_ADMIN: '/cont',
        CREATE_USER_ADMIN: '/cont/admin',
        GET_CREDITE_USER_ADMIN: '/raportSummary/user',
        ADD_CREDITE_USER_ADMIN: '/cont/adaugaCredite',
        GET_ACHIZITII_USER_ADMIN: '/achizitie/user',
        GET_CONSUM_USER_ADMIN: '/raport/user',
        INSERT_COMANDA_FIN: '/admin/parserFin/insertCuis',
        GET_FIN_STATUS: '/admin/parserFin/getStatus',
        INSERT_COMANDA_CIP: '/admin/parserCip/insertCuis',
        GET_CIP_STATUS: '/admin/parserCip/getCipStatus',
        GET_EMAILS_THRESHOLD: '/admin/emails/getEmailsThreshold',
        UPDATE_MONITORIZARE: '/admin/emails/updateMonitorizare',
        GET_API_STATUS: '/admin/api/getStatus',
        VERIFY_API: '/admin/api/verifyApi',
        DELETE_RAPORT: '/raport/delete'
    }
};

export default settings;
