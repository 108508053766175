import {createActions} from 'reflux';
import settings from '../settings';
import axios from 'axios';
import { notify } from 'react-notify-toast';

const AdminActions = createActions({
    setAction: {},
    hideFooter: {},
    showFooter: {},
    getUsers: {asyncResult: true},
    searchUser: {asyncResult: true},
    updateUser: {asyncResult: true},
    addUser: {asyncResult: true},
    deleteUser: {asyncResult: true},
    getCrediteUser: {asyncResult: true},
    addCrediteUser: {asyncResult: true},
    getAchizitiiUser: {asyncResult: true},
    getConsumUser: {asyncResult: true},
    insertComandaFin: {asyncResult: true},
    getStatusFin: {asyncResult: true},
    insertComandaCip: {asyncResult: true},
    getCipStatus: {asyncResult:true},
    getEmailsThreshold: {asyncResult: true},
    updateMonitorizare: {asyncResult: true},
    getApiStatus: {asyncResult: true},
    verifyApi: {asyncResult: true},
    resetApi: {},
    deleteRaport: {asyncResult: true},
})

AdminActions.getUsers.listen((pageSize, pageIngex) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_USERS_ADMIN + '?pageSize=' + pageSize +'&pageIndex=' + pageIngex
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getUsers.completed(data.Result);
    }).catch((error) => {
        AdminActions.getUsers.failed(error);
    });
})

AdminActions.searchUser.listen((pageSize, pageIngex, query) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_USER_ADMIN + '?pageSize=' + pageSize +'&pageIndex=' + pageIngex + '&query=' + query
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.searchUser.completed(data);
    }).catch((error) => {
        AdminActions.searchUser.failed(error);
    });
})

AdminActions.updateUser.listen((userU) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_USER_ADMIN
    axios.put(
        url, userU,
        {
            headers: headers,
        }
    ).then((response) => {
        // const { data } = response;
        AdminActions.updateUser.completed(userU);
        notify.show('Succes', 'success', 3000);
    }).catch((error) => {
        // AdminActions.updateUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.addUser.listen((userU) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CREATE_USER_ADMIN
    axios.post(
        url, userU,
        {
            headers: headers,
        }
    ).then((response) => {
        // const { data } = response;
        // AdminActions.addUser.completed(data.Result);
        AdminActions.setAction()
        notify.show('Succes', 'success', 3000);
    }).catch((error) => {
        // AdminActions.addUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_USER_ADMIN + '/' + userId
    axios.delete(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        // const { data } = response;
        // AdminActions.deleteUser.completed(data.Result);
        AdminActions.setAction()
        notify.show('Success', 'success', 3000);
    }).catch((error) => {
        // AdminActions.deleteUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getCrediteUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_CREDITE_USER_ADMIN + '/' + userId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getCrediteUser.completed(data.Result);
    }).catch((error) => {
        AdminActions.getCrediteUser.failed(error);
        notify.show('Error', error, 3000);
    });
})

AdminActions.addCrediteUser.listen((credite) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.ADD_CREDITE_USER_ADMIN
    axios.post(
        url, credite,
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.addCrediteUser.completed(credite);
    }).catch((error) => {
        notify.show('Error', error, 3000);
    });
})

AdminActions.getAchizitiiUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_ACHIZITII_USER_ADMIN + '/' + userId + '?skipIds=0&takeIds=10'
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getAchizitiiUser.completed(data.Result);
    }).catch((error) => {
        AdminActions.getAchizitiiUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getConsumUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_CONSUM_USER_ADMIN + '/' + userId + '?skipIds=0&takeIds=10'
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getConsumUser.completed(data.Result);
    }).catch((error) => {
        AdminActions.getConsumUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.insertComandaFin.listen((comanda) => {        //trimite cui-uri la parsat
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.INSERT_COMANDA_FIN
    axios.post(
        url, comanda,
        {
            headers: headers,
        }
    ).then((response) => {
        notify.show('Comanda inserata cu success', 'success', 3000);
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getStatusFin.listen((tip) => {        //trimite cui-uri la parsat
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_FIN_STATUS + '/' + tip
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        AdminActions.getStatusFin.completed(data)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.insertComandaCip.listen((comanda) => {        //trimite cui-uri la parsat
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.INSERT_COMANDA_CIP
    axios.post(
        url, comanda,
        {
            headers: headers,
        }
    ).then((response) => {
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getCipStatus.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_CIP_STATUS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getCipStatus.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getEmailsThreshold.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_EMAILS_THRESHOLD
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getEmailsThreshold.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.updateMonitorizare.listen(monitorizareInfo => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_MONITORIZARE
    axios.post(
        url, monitorizareInfo,
        {
            headers: headers,
        }
    ).then((response) => {
        notify.show('Succes', 'success', 3000);
        AdminActions.getEmailsThreshold()
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getApiStatus.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_API_STATUS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getApiStatus.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.verifyApi.listen((requestData) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.VERIFY_API
    axios.post(
        url, requestData,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.verifyApi.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteRaport.listen((raportId, userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.DELETE_RAPORT + '/' + raportId
    axios.delete(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        AdminActions.getConsumUser(userId)
        notify.show('Success', 'success', 3000);
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

export default AdminActions